import { createSlice } from '@reduxjs/toolkit';
import { activityDisplayCorrectionMode } from 'utils/activity.util';
import {
  activityItemStates,
  evaluate,
  activityItemEvaluationMethod,
} from 'utils/activityItem.util';

export const evaluationsSlice = createSlice({
  name: 'evaluations',
  initialState: [],
  reducers: {
    setEvaluations: (_, action) => {
      const { itemsDisplayCorrectionMode, items } =
        action.payload.activity || {};
      return items?.map((x) => {
        let itemState = activityItemStates.NOT_ANSWERED;
        let evaluation = null;
        if (x.answer) {
          itemState = activityItemStates.ANSWERED;
          const displayCorrectionMode =
            x.item.displayCorrectionMode !== null
              ? x.item.displayCorrectionMode
              : itemsDisplayCorrectionMode;
          if (displayCorrectionMode !== activityDisplayCorrectionMode.NONE) {
            itemState = evaluation?.isCorrect
              ? activityItemStates.CORRECT
              : activityItemStates.INCORRECT;
          }
          evaluation = evaluate(x.item, x.answer);
          x.answer.markedForManualEvaluation =
            x.item.evaluationMethod === activityItemEvaluationMethod.MANUAL;
        }
        return {
          itemId: x.item.id,
          title: x.item.title,
          state: itemState,
          itemType: x.item.itemType,
          evaluation,
          isEvaluate:
            x.item.evaluationMethod !== activityDisplayCorrectionMode.NONE,
          answer: x.answer,
        };
      });
    },
    updateEvaluations: (_, action) => {
      return action.payload;
    },
    setItemEvaluation: (state, action) => {
      const payloadDisplayCorrectionMode =
        action.payload?.item?.displayCorrectionMode;
      const evaluations = [...state];
      let itemState = activityItemStates.ANSWERED;
      const displayCorrectionMode =
        payloadDisplayCorrectionMode !== null
          ? payloadDisplayCorrectionMode
          : state.itemsDisplayCorrectionMode;
      if (displayCorrectionMode !== activityDisplayCorrectionMode.NONE) {
        itemState = action.payload?.evaluation?.isCorrect
          ? activityItemStates.CORRECT
          : activityItemStates.INCORRECT;
      }
      return evaluations.map((evaluation) => {
        if (evaluation.itemId === action.payload?.item?.id) {
          return {
            ...evaluation,
            state: itemState,
            evaluation: action.payload?.evaluation,
            answer: action.payload?.answer,
          };
        } else {
          return evaluation;
        }
      });
    },
    resetItemEvaluation: (state, action) => {
      const evaluations = [...state];
      return evaluations.map((evaluation) => {
        if (evaluation.itemId === action.payload?.item?.id) {
          return {
            ...evaluation,
            state: activityItemStates.NOT_ANSWERED,
            evaluation: null,
            answer: null,
          };
        } else {
          return evaluation;
        }
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEvaluations, updateEvaluations, setItemEvaluation, resetItemEvaluation } =
  evaluationsSlice.actions;

export default evaluationsSlice.reducer;
